import React, { useState } from 'react';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { PeopleSelector } from '../components/form/PeopleSelector';
import { cvDocxDownloadLink } from '../api';
// import { CheckboxComponent } from '../components/CheckboxComponent';
import { LanguageSelectDropdown } from '../components/LanguageSelectDropdown';
import FileDownloadButton from '../components/FileDownloadButton';

interface Language {
  id: string,
  language: string,
}

export const CurriculumVitae: React.FC = () => {
  const { t } = useTranslation();

  const [selectedId, setSelectedUid] = useState<string | undefined>();
  const [addExternalIds /* , setAddExternalIds */] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState<Language>(
    {
      id: "fi",
      language: "Finnish",
    }
  );

  return (
    <Box display="flex" flexDirection="column" gap={3} justifyContent="center" alignItems="center">
      <PeopleSelector
        onChangeSelectedId={setSelectedUid}
        id={selectedId}
        label={t('peopleSelector.selectEmployee')}
        isRequired={true}
      />
      <LanguageSelectDropdown
        setSelectedLanguage={setSelectedLanguage}
        selectedLanguage={selectedLanguage}
      />
      {/* Temporarely disabled 'include Severa and Majakka iD:s' checkbox */}
      {/* <Box alignItems="left" justifyContent="flex-start" width={480} >
        <CheckboxComponent 
          onOffState={addExternalIds} 
          setOnOffState={setAddExternalIds}
          labelText={t('includeSeveraAndMajakkaIDs')}
        />
      </Box> */}
      <Box display="flex" flexDirection="column" gap={1} margin={0}>
        <FileDownloadButton
          disabled={!selectedId}
          href={cvDocxDownloadLink(
            selectedId || '',
            addExternalIds,
            selectedLanguage.id,
          )}
          text={t('cv.downloadCV')}
        />
      </Box>
    </Box>
  );
};