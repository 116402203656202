import React, { useEffect, useState } from 'react';
import { Save as SaveIcon } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { useMsal } from '@azure/msal-react';

const FileDownloadButton = ({ href, text, disabled }) => {
  const [loading, setLoading] = useState(false);
  const msalInstance = useMsal()
  const [accessToken, setAccessToken] = useState("")

  // TODO: Move download queries under api so that they do not need to ask for access tokens separately
  useEffect(() => {
    const getAccessToken = async () => {
      if (process.env.CONFIG_REQUIRE_AUTH === "false") {
        return
      }
      const accounts = msalInstance.instance.getAllAccounts
      if (accounts.length === 0) {
        console.log("Msal instance has no accounts, login needed")
        return
      }
      const accessTokenRequest = {
        scopes: ["api://a608d2e8-592d-4e58-87fe-ca186d02fd18/ReferenceTool.Use"],
        account: accounts[0],
      }
      let at = ''
      await msalInstance.instance.acquireTokenSilent(accessTokenRequest).then(
        (accessTokenResponse) => {
          at = accessTokenResponse.accessToken
        }).catch(function (error) {
          console.log(error)
        })
      setAccessToken(at);
    };
    getAccessToken();
  }, []);

  const downloadFile = async () => {
    try {
      setLoading(true);

      const requestHeaders: HeadersInit = new Headers();
      requestHeaders.set('Authorization', accessToken);

      const fileUrl = href;
      const response = await fetch(fileUrl, { headers: requestHeaders });

      const contentDisposition = response.headers.get('content-disposition');
      const filename = contentDisposition
        ? contentDisposition.split('filename=')[1]
        : 'filename.txt';

      const blob = await response.blob();

      const url = window.URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);

      link.click();

      console.log('File downloaded successfully!');
    } catch (error) {
      console.error('Error downloading file:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <LoadingButton
        size={'large'}
        startIcon={<SaveIcon />}
        onClick={downloadFile}
        loading={loading}
        variant="contained"
        disabled={loading || disabled}
        loadingPosition="start"
      >
        <span>{text}</span>
      </LoadingButton>
    </>
  );
};

export default FileDownloadButton;
