import React from "react";
import { SvgFlagIcon } from "./projects/types";
import { Tooltip, Typography, Box } from "@mui/material";

interface FlagProps {
  width?: string;
  border?: string;
  borderRadius?: string;
  opacity?: number;
  marginRight?: string;
  tooltipText?: string;
  transform?: string;
  transformOrigin?: string;
  flagType?: string;
}

const flagIcon: React.FC<FlagProps> = (flagProps) => {
  const {
    width,
    border,
    borderRadius,
    marginRight,
    opacity,
    tooltipText,
    transform,
    transformOrigin,
    flagType,
  } = flagProps;

  const svgStyles = {
    transform,
    transformOrigin,
    marginRight,
    width,
    border,
    borderRadius,
    opacity,
  } as React.CSSProperties;

  const svgFlagIcons: SvgFlagIcon[] = [
    {
      languageCode: 'fi',
      svgFlag: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          id="flag-icons-fi"
          viewBox="0 0 640 480"
          style={svgStyles}
        >
          <path fill="#fff" d="M0 0h640v480H0z" />
          <path fill="#002f6c" d="M0 174.5h640v131H0z" />
          <path fill="#002f6c" d="M175.5 0h130.9v480h-131z" />
        </svg>
      ),
    },
    {
      languageCode: 'gb',
      svgFlag: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          id="flag-icons-gb"
          viewBox="0 0 640 480"
          style={svgStyles}
        >
          <path fill="#012169" d="M0 0h640v480H0z" />
          <path
            fill="#FFF"
            d="m75 0 244 181L562 0h78v62L400 241l240 178v61h-80L320 301 81 480H0v-60l239-178L0 64V0z"
          />
          <path fill="#C8102E" d="m424 281 216 159v40L369 281zm-184 20 6 35L54 480H0zM640 0v3L391 191l2-44L590 0zM0 0l239 176h-60L0 42z" />
          <path fill="#FFF" d="M241 0v480h160V0zM0 160v160h640V160z" />
          <path fill="#C8102E" d="M0 193v96h640v-96zM273 0v480h96V0z" />
        </svg>
      ),
    },
    {
      languageCode: 'dk',
      svgFlag: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          id="flag-icons-dk"
          viewBox="0 0 640 480"
          style={svgStyles}
        >
          <path fill="#c8102e" d="M0 0h640.1v480H0z" />
          <path fill="#fff" d="M205.7 0h68.6v480h-68.6z" />
          <path fill="#fff" d="M0 205.7h640.1v68.6H0z" />
        </svg>
      ),
    },
    {
      languageCode: 'sv',
      svgFlag: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          id="flag-icons-se"
          viewBox="0 0 640 480"
          style={svgStyles}
        >
          <path fill="#005293" d="M0 0h640v480H0z" />
          <path fill="#fecb00" d="M176 0v192H0v96h176v192h96V288h368v-96H272V0z" />
        </svg>
      ),
    },
  ];

  const selectedFlag = svgFlagIcons.find((flag) => flag.languageCode === flagType);

  return (
    <>
      <Tooltip
        title={
          tooltipText ? (
            <React.Fragment>
              <Typography color="inherit" fontSize="caption.fontSize" textAlign="center">
                {tooltipText}
              </Typography>
            </React.Fragment>
          ) : (
            ''
          )
        }
        placement="top"
        slotProps={{
          popper: {
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, 0],
                },
              },
            ],
          },
        }}
      >
      <Box display="flex" alignItems="center">
        {selectedFlag ? selectedFlag.svgFlag : ''}
      </Box>
    </Tooltip>
    </>
  );
};

export default flagIcon;
