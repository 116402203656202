import { IconButton, Snackbar } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { AlertContext } from '../context/AlertContext';
import { Alert } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export const AlertSnackbar: React.FC = () => {
  const { message, severity, setMessage } = useContext(AlertContext);
  const [isOpen, setIsOpen] = useState(false);

  const handleClose = () => {
    setIsOpen(false);

    setTimeout(() => {
      setMessage(undefined, severity);
    }, 500);
  };

  useEffect(() => {
    if (message) {
      setIsOpen(true);
    }
  }, [message]);

  return (
    <Snackbar
      open={isOpen}
      autoHideDuration={5000}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    >
      <Alert elevation={6} variant="filled" severity={severity}>
        {message}

        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={handleClose}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </Alert>
    </Snackbar>
  );
};
