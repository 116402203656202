import * as React from 'react';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import { Data } from './types';
import { TableToolbar } from './TableToolbar';
import { useEffect, useState } from 'react';
import { ProjectSummaryTableContainer } from './ProjectSummaryTableContainer';
import { useSelection } from '../../hooks/useSelection';
import { Stack } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { useGetProjectsQuery } from '../../store/services/api';

interface Props {
  onChangeSelected?: (selected: readonly string[]) => void;
  onSelect?: (selectedProjectId: string) => void;
  selectable?: boolean;
}

export interface OrderBy {
  orderBy?: keyof Data;
  ascending: boolean;
}

export const ProjectSummaryTable: React.FC<Props> = (props: Props) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [rows, setRows] = useState<Data[]>([]);
  const [orderBy, setOrderBy] = useState<OrderBy>({
    ascending: false,
  });
  const { onChangeSelected, onSelect, selectable } = props;

  const projectSummarySearchOpts = useSelector(
    (state: RootState) => state.projectSummarySearchOpts,
  );

  const { data: projectSummaries, isFetching } = useGetProjectsQuery({
    ...projectSummarySearchOpts,
    orderBy: orderBy.orderBy,
    ascending: orderBy.ascending,
  }
  );

  const { changeSelection, isSelected, selected, setSelected } =
    useSelection<string>();

  useEffect(() => {
    if (!projectSummaries) {
      return;
    }
    const rows = projectSummaries.data.projects.map((props): Data => {
      const { id, name, scope, started, ended, referenceStatus } = props;

      return {
        id,
        name: name || '',
        scope: scope != undefined && scope > 0 ? Math.round(scope) : undefined,
        started: started ? new Date(started) : undefined,
        ended: ended ? new Date(ended) : undefined,
        referenceStatus: referenceStatus || undefined,
      };
    });
    setPage(0);
    setRows(rows);
    selectable && setSelected([]);
  }, [projectSummaries]);

  useEffect(() => {
    onChangeSelected?.(selected);
  }, [selected]);

  const handleChangePage = (_: React.MouseEvent | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    if (Math.ceil(rows.length / newRowsPerPage) <= page) {
      setPage(0);
    }
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  return (
    <Stack>
      <Paper>
        <TableToolbar
          isFetching={isFetching}
          numSelected={selectable ? selected.length : undefined}
        />

        <ProjectSummaryTableContainer
          onSelect={onSelect}
          rows={rows}
          page={page}
          rowsPerPage={rowsPerPage}
          selectable={selectable}
          selected={selected}
          setSelected={setSelected}
          changeSelection={changeSelection}
          isSelected={isSelected}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
        />

        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          slotProps={{
            actions: {
              nextButton: {
                color: 'primary',
              },
              previousButton: {
                color: 'primary',
              }
            }
          }}
        />
      </Paper>
    </Stack>
  );
};
