import 'dotenv/config'

const BASE_URI =
  process.env.MOCKED_API === 'true'
    ? 'localhost:4280/api'
    : process.env.API_URL?.replace(/\/$/, '') || '/api'

export function cvDocxDownloadLink(
    userUid: string, 
    addExternalIds: boolean,
    selectedLanguage: string,
    ): string {
  return `${BASE_URI}/1/users/${userUid}/cv-references.docx`
    +`?add_external_ids=${addExternalIds}&selected_language=${encodeURIComponent(selectedLanguage)}`
}

export function referencesDocxDownloadLink(
  projectUids: readonly string[],
  addExternalIds: boolean,
  selectedLanguage: string,
): string {
  return `${BASE_URI}/1/projects/references.docx?ids=${encodeURIComponent(
    projectUids.join(','),
  )}&add_external_ids=${addExternalIds}&selected_language=${
    encodeURIComponent(selectedLanguage)}`
}

export function referencesPptxDownloadLink(
  projectUids: readonly string[],
  selectedLanguage: string,
): string {
  return `${BASE_URI}/1/projects/cv-references.pptx?ids=${encodeURIComponent(
    projectUids.join(','), 
  )}&selected_language=${
    encodeURIComponent(selectedLanguage)}`
}
