import React, { useState } from 'react';
import { Stack, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ProjectSummaries } from '../components/projects/ProjectSummaries';
import { referencesDocxDownloadLink, referencesPptxDownloadLink } from '../api';
import { LanguageSelectDropdown } from '../components/LanguageSelectDropdown';
import { useNavigate } from 'react-router-dom';
import { ReferenceEditor } from '../components/references/ReferenceEditor';
import FileDownloadComponent from '../components/FileDownloadButton';
import { selectableLanguage } from '../components/references/ReferenceEditor';
import { useSelector } from 'react-redux';
import { getSearchURLParams, } from '../store/features/projectSummarySearchSlice';
import { RootState } from '../store/store';

export const References: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const search = useSelector(
    (state: RootState) => state.projectSummarySearchOpts,
  );

  const [selectedRefs, setSelectedRefs] = useState<readonly string[]>([]);
  const [addExternalIds] = useState(false);

  const [selectedLanguage, setSelectedLanguage] = useState<{ id: string, language: string }>(
    selectableLanguage[0]
  )

  const SelectedRefsEmpty = selectedRefs.length === 0;

  const handleSelect = (selectedProjectId: string) => navigate(`/references/${selectedProjectId}`);
  const handleClose = () => {
    const searchUrlParams = getSearchURLParams(search)
    navigate("/references" + `?${searchUrlParams.toString()}`)
  };

  return (
    <>
      <ProjectSummaries onChangeSelected={setSelectedRefs} onSelect={handleSelect}>
        <Stack direction="row" gap={4} justifyContent="center" alignItems="center">
          <Box display="flex" flexDirection="column" gap={2} alignItems="center">
            <LanguageSelectDropdown
              setSelectedLanguage={setSelectedLanguage}
              selectedLanguage={selectedLanguage}
            />
            <Box display="flex" flexDirection="row" gap={3}>
              <FileDownloadComponent
                href={referencesDocxDownloadLink(
                  selectedRefs,
                  addExternalIds,
                  selectedLanguage.id,
                )}
                text={t('projects.downloadForWord')}
                disabled={SelectedRefsEmpty}
              />
              <FileDownloadComponent
                href={referencesPptxDownloadLink(
                  selectedRefs,
                  selectedLanguage.id,
                )}
                text={t('projects.downloadForPowerpoint')}
                disabled={SelectedRefsEmpty}
              />
              {/* <Button
              href={referencesDocxDownloadLink(
                selectedRefs, 
                addExternalIds, 
                selectedLanguage.id,
              )}
              sx={{ height: '3rem' }}
              download
              disabled={SelectedRefsEmpty}
            >
                {t('projects.downloadForWord')}
            </Button>
            <Button
              href={referencesPptxDownloadLink(
                selectedRefs,
                selectedLanguage.id,
              )}
              sx={{ height: '3rem' }}
              download
              disabled={SelectedRefsEmpty}
            >
                {t('projects.downloadForPowerpoint')}
            </Button> */}
            </Box>
          </Box>
        </Stack>
      </ProjectSummaries>
      <ReferenceEditor onClose={handleClose} />
    </>
  );
};
