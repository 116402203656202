import React, { useEffect } from 'react';

import {
  Dialog,
  DialogContent,
  DialogProps,
  DialogTitle,
  Stack,
  TextField,
  MenuItem,
  Button,
  Typography,
} from '@mui/material';
import { Project } from '../../api/types'
import { EditorTools } from './EditorTools';
import { ReferenceForm } from './ReferenceForm';
import { Control, UseFormRegister, UseFormWatch, useFormState } from 'react-hook-form';
import { Language, FormValues, selectableLanguage } from './ReferenceEditor';
import { ProjectInformationTable } from './ProjectInformationTable';

interface Props extends DialogProps {
  control: Control<FormValues>;
  isLoading: boolean;
  project: Project;
  selectedLanguage: Language;
  onClose?: () => void;
  onSave: () => void;
  openConfirmationDialog: () => void;
  openDiscardDialog: () => void;
  openChangeLanguageDialog: () => void;
  register: UseFormRegister<FormValues>;
  watch: UseFormWatch<FormValues>;
  setSelectedLanguage: (Language) => void;
  deactivateTranslate: () => void;
  activateTranslate: () => void;
  setSelectedLanguagePending: React.Dispatch<React.SetStateAction<{
    id: string;
    language: string;
  }>>
  enableTranslateAllButton: () => boolean;
}

export const ReferenceDialog: React.FC<Props> = (props) => {
  const {
    control,
    isLoading,
    project,
    selectedLanguage,
    onClose,
    onSave,
    openConfirmationDialog,
    openDiscardDialog,
    openChangeLanguageDialog,
    register,
    watch,
    setSelectedLanguage,
    deactivateTranslate,
    activateTranslate,
    setSelectedLanguagePending,
    enableTranslateAllButton,
    ...rest
  } = props;

  const { isDirty } = useFormState({ control });

  const handleCancel = () => isDirty ? openDiscardDialog() : null;
  const handleClose = () => isDirty ? openConfirmationDialog() : onClose?.();

  useEffect(() => {
    const unloadConfirmation = (event: BeforeUnloadEvent) => {
      if (!isDirty) {
        return;
      }
      event.preventDefault();
      event.returnValue = confirm();
    };
    window.addEventListener('beforeunload', unloadConfirmation);

    return () => window.removeEventListener('beforeunload', unloadConfirmation);
  }, [isDirty]);

  return (
    <Dialog
      scroll="paper"
      onClose={handleClose}
      fullWidth={true}
      maxWidth="xl"
      {...rest}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
        <Stack direction="column" alignItems="left">
          <DialogTitle component="span">{project.name}</DialogTitle>
          <ProjectInformationTable project={project} />
        </Stack>

        <Stack direction="row" justifyContent="space-between" alignItems="flex-start" sx={{ marginTop: 4 }}>
          <Stack direction="column" alignItems="center" sx={{ paddingRight: 5 }}>
            <TextField
              select
              style={{ width: 200 }}
              label="Change reference language"
              value={selectedLanguage.id}
              onChange={(event) => {
                const selectedId = event.target.value
                const selectedLang = selectableLanguage.find(lang => lang.id === selectedId)
                if (selectedLang) {
                  if (!isDirty) {
                    deactivateTranslate()
                    setSelectedLanguage(selectedLang)
                  } else {
                    setSelectedLanguagePending(selectedLang)
                    openChangeLanguageDialog()
                  }
                }
              }}
            >
              {selectableLanguage.map((lang) => (
                <MenuItem key={lang.id} value={lang.id}>
                  {lang.language}
                </MenuItem>
              ))}
            </TextField>

            <Button
              variant="contained"
              onClick={activateTranslate}
              sx={{ width: 200, marginTop: 2.5 }}
              disabled={isLoading || !(enableTranslateAllButton())}
            >
              Translate all
            </Button>

          </Stack>

          <EditorTools
            handleCancel={handleCancel}
            handleClose={handleClose}
            handleSave={onSave}
            isDirty={isDirty}
            isLoading={isLoading}
          />
        </Stack>
      </Stack>

      <DialogContent sx={{ marginY: '3em' }}>
        <Typography variant='caption'>*Required field</Typography>
        <ReferenceForm
          register={register}
          watch={watch}
          isLoading={isLoading}
          selectedLanguage={selectedLanguage}
          control={control}
        />
      </DialogContent>
    </Dialog>
  );
};
