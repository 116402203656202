import * as React from 'react';
import { TextField, MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface Language {
  id: string,
  language: string,
}

export interface LanguageSelectDropdownProps {
  setSelectedLanguage: (languages: Language) => void,
  selectedLanguage: Language,
}

export const LanguageSelectDropdown: React.FC<LanguageSelectDropdownProps> = ({
  setSelectedLanguage, selectedLanguage
}) => {

  const { t } = useTranslation();

  const selectableLanguage: Language[] = [
    { id: "fi", language: "Finnish"},
    { id: "en", language: "English"},
    { id: "sv", language: "Swedish"},
    { id: "da", language: "Danish"},
  ];

  return (
    <TextField
      select
      fullWidth
      label={t('languageSelector.selectLanguage')}
      value={selectedLanguage.id}
      onChange={(event) => {
        const selectedId = event.target.value
        const selectedLang = selectableLanguage.find(lang => lang.id === selectedId)
        if (selectedLang) {
          setSelectedLanguage(selectedLang)
        }
      }}
    >
      {selectableLanguage.map((lang) => (
        <MenuItem key={lang.id} value={lang.id}>
          {lang.language}
        </MenuItem>
      ))}
    </TextField>
  );
}