import { Stack, TextField, Typography } from '@mui/material';
import React from 'react';
import { Path, Control, UseFormRegister, UseFormWatch, useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Translation } from '../../i18n';
import { FormValues, Language } from './ReferenceEditor';
import InputAdornment from '@mui/material/InputAdornment';

interface Props {
  isLoading: boolean;
  register: UseFormRegister<FormValues>;
  watch: UseFormWatch<FormValues>;
  selectedLanguage: Language;
  control: Control<FormValues>;
}

interface RefTextFieldConfig {
  contentPath: Path<FormValues>,
  isApprovedPath: Path<FormValues>,
  translationKey: keyof Translation['references']['edit'],
  referenceKey: Path<FormValues>,
  isRequired: boolean,
}

// TODO: tidy this up, isRequired should come from a global config acquired from backend
const inputFieldConfigs: RefTextFieldConfig[] = [{
  contentPath: "Introduction.content",
  isApprovedPath: "Introduction.isApproved",
  translationKey: "introduction",
  referenceKey: "Introduction",
  isRequired: true,
},
{
  contentPath: "Objectives.content",
  isApprovedPath: "Objectives.isApproved",
  translationKey: "objectives",
  referenceKey: "Objectives",
  isRequired: true,
},
{
  contentPath: "Execution.content",
  isApprovedPath: "Execution.isApproved",
  translationKey: "execution",
  referenceKey: "Execution",
  isRequired: true,
},
{
  contentPath: "Methods.content",
  isApprovedPath: "Methods.isApproved",
  translationKey: "methods",
  referenceKey: "Methods",
  isRequired: false,
},
{
  contentPath: "Results.content",
  isApprovedPath: "Results.isApproved",
  translationKey: "results",
  referenceKey: "Results",
  isRequired: true,
},
{
  contentPath: "AdditionalInformation.content",
  isApprovedPath: "AdditionalInformation.isApproved",
  translationKey: "additionalInformation",
  referenceKey: "AdditionalInformation",
  isRequired: false,
},
{
  contentPath: "CVReference.content",
  isApprovedPath: "CVReference.isApproved",
  translationKey: "cvReference",
  referenceKey: "CVReference",
  isRequired: true,
},
{
  contentPath: "CustomerContacts.content",
  isApprovedPath: "CustomerContacts.isApproved",
  translationKey: "customerContacts",
  referenceKey: "CustomerContacts",
  isRequired: true,
},
{
  contentPath: "Scope.content",
  isApprovedPath: "Scope.isApproved",
  translationKey: "scope",
  referenceKey: "Scope",
  isRequired: true,
},
{
  contentPath: "Duration.content",
  isApprovedPath: "Duration.isApproved",
  translationKey: "duration",
  referenceKey: "Duration",
  isRequired: true,
},
]

export const ReferenceForm: React.FC<Props> = (props) => {
  const { isLoading, register, watch, selectedLanguage, control } = props;
  const { t } = useTranslation('translation');
  const { dirtyFields } = useFormState({ control });

  const trKey = (prop: string, prop2: string) => `references.edit.${prop}.${prop2}`;
  const referenceEditPlaceholderKeyFor = (prop: string) => trKey(prop, 'placeholder');
  const referenceEditLabelKeyFor = (prop: string) => trKey(prop, 'label');

  const InputField = (config: RefTextFieldConfig) => {
    const content = watch(config.contentPath);
    return (
      <TextField
        required={config.isRequired}
        key={config.contentPath}
        id={config.contentPath}
        sx={{
          "& .MuiInputBase-root.MuiOutlinedInput-root ::placeholder": {
            color: '#9e9e9e',
          }
        }}
        {...register(config.contentPath)}
        placeholder={t(referenceEditPlaceholderKeyFor(config.translationKey), { lng: selectedLanguage.id })}
        label={t(referenceEditLabelKeyFor(config.translationKey))}
        multiline
        minRows={2}
        InputLabelProps={{ shrink: true }}
        InputProps={{
          disabled: isLoading,
          endAdornment: (
            <InputAdornment position="end" sx={{ paddingLeft: 1, paddingRight: 1 }}>
              {(dirtyFields[config.referenceKey]?.content || content) && (
                <label className="container">
                  <input
                    className="checkbox"
                    type="checkbox"
                    {...register(config.isApprovedPath)}
                  />
                  <span className="checkmark"></span>
                </label>
              )}
            </InputAdornment>
          ),
        }}
      />
    );
  };

  return (
    <>
      {<Stack direction="row" justifyContent="end">
        <Typography sx={{ padding: 1, color: "green" }}>Approved</Typography>
      </Stack>}
      <Stack gap={4}>
        {inputFieldConfigs.map((config) => InputField(config))}
      </Stack>
    </>
  );
};
