import { Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Route } from '../types';
import { RouteTabs } from './RouteTabs';
import { Outlet, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { SignInButton } from './SignInButton';
import { useIsAuthenticated } from '@azure/msal-react';
import { SignOutButton } from './SignOutButton';

interface Props {
  routes: Route[];
}

export const Navigation: React.FC<Props> = (props) => {
  const { routes } = props;
  const location = useLocation();
  const [idx, setIdx] = useState(0);

  useEffect(() => {
    const newIdx = routes.findIndex(
      (r) => r.path && location.pathname.includes(r.path),
    );

    if (newIdx > -1) {
      setIdx(newIdx);
    } else {
      setIdx(0);
    }
  }, [location]);

  const { t } = useTranslation();
  const isAuthenticated = process.env.CONFIG_REQUIRE_AUTH !== "false" ? useIsAuthenticated() : true

  return (
    <Stack alignItems="center">
      <Stack
        direction="row"
        justifyContent="space-between"
        width="100%"
        alignItems="center"
      >
        <Stack flex={1}>
          <img
            src={require('../assets/images/nhg_logo.png')}
            style={{ width: '13em', paddingLeft: '3.5em', paddingTop: '3.5em' }}
          />
        </Stack>

        <Stack flex={1} alignItems="center">
          <Typography variant="h4">{t('nav.heading')}</Typography>
        </Stack>

        <Stack flex={1} alignItems="center">
          {isAuthenticated ? <SignOutButton /> : <SignInButton />}
        </Stack>
      </Stack>

      {isAuthenticated && <Stack direction="row" paddingBottom={3}>
        <RouteTabs routes={routes} idx={idx} />
      </Stack>}

      {isAuthenticated && <Outlet />}

      {!isAuthenticated && <Stack direction="row" paddingBottom={3}>
        <Typography variant="body1">{t('signInPrompt')}</Typography>
      </Stack>}
    </Stack>
  );
};
