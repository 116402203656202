
export type APIResponse<T> = {
  data: T;
}

export type APIListResponse<T> = {
  data: T[];
}

export interface ProjectWorker {
  id: string;
  name: string;
}

export interface ReferenceStatus {
  hasFinnishAllApproved: boolean;
  hasEnglishAllApproved: boolean;
  hasSwedishAllApproved: boolean;
  hasDanishAllApproved: boolean;
  hasAnyApprovedCVRef: boolean;
}

export interface Project {
  id: string;
  name: string;
  customerName: string;
  owner: string;
  businessUnit: string;
  confidentiality: Confidentiality;
  scope?: number;
  scopeManDaysWorked?: number;
  started: string;
  ended?: string;
  projectOwner?: string;
  severaNumber?: string;
  referenceStatus: ReferenceStatus;
}

export enum Confidentiality {
  Public = "Public",
  Restricted = "Restricted",
  Confidential = "Confidential",
}

export interface FindProjectsResult {
  isCompleteList: boolean;
  projects: Project[];
}

export interface ProjectReference {
  key: string;
  content: string;
  languageCode: string;
  translationAvailable: boolean;
  isApproved: boolean;
}

export interface ProjectReferences {
  project: Project;
  references: ProjectReference[];
}

export interface ProjectQuery {
  fullTextSearch?: string;
  customer?: string;
  projectOwner?: string;
  salesperson?: string;
  businessUnit?: string;
  scopeInManDays?: MinMax;
  yearsPassed?: MinMax;
  orderBy?: string; // scope, started or ended
  ascending?: boolean;
}

export interface MinMax<T = number> {
  min?: T;
  max?: T;
}

export interface BusinessUnit {
  id: string;
  name: string;
}
