import { configureStore } from '@reduxjs/toolkit';
import projectSummarySearchReducer from './features/projectSummarySearchSlice';
import projectSummariesReducer from './features/projectSummariesSlice';
import { api } from './services/api';

export const store = configureStore({
  reducer: {
    projectSummarySearchOpts: projectSummarySearchReducer,
    projectSummaries: projectSummariesReducer,
    [api.reducerPath]: api.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(api.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
