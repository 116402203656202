import Ajv, { JSONSchemaType, ValidateFunction } from 'ajv';
import {
  MinMax,
  Project,
  ProjectReference,
  ProjectReferences,
  ProjectQuery,
  ProjectWorker,
  APIResponse,
  APIListResponse,
  FindProjectsResult,
  ReferenceStatus,
  BusinessUnit,
} from './types';

const referenceStatusSchema: JSONSchemaType<ReferenceStatus> = {
  //$id: 'referenceStatus', // no needed?
  type: 'object',
  properties: {
    hasFinnishAllApproved: { type: 'boolean' },
    hasEnglishAllApproved: { type: 'boolean' },
    hasSwedishAllApproved: { type: 'boolean' },
    hasDanishAllApproved: { type: 'boolean' },
    hasAnyApprovedCVRef: { type: 'boolean' },
  },
  required: [
    'hasFinnishAllApproved',
    'hasEnglishAllApproved',
    'hasSwedishAllApproved',
    'hasDanishAllApproved',
    'hasAnyApprovedCVRef'
  ]
}

const projectSchema: JSONSchemaType<Project> = {
  $id: 'project',
  type: 'object',
  properties: {
    id: { type: 'string' },
    name: { type: 'string' },
    customerName: { type: 'string' },
    salesperson: { type: 'string' },
    owner: { type: 'string' },
    businessUnit: { type: 'string' },
    confidentiality: { type: 'string' },
    scope: { type: 'number', nullable: true },
    scopeManDaysWorked: { type: 'number', nullable: true },
    started: { type: 'string' },
    ended: { type: 'string', nullable: true },
    projectOwner: { type: 'string', nullable: true },
    severaNumber: { type: 'string', nullable: true },
    referenceStatus: {
      ...referenceStatusSchema,
      nullable: false,
    },
  },
  required: ['id', 'name'],
};

const findProjectsSchema: JSONSchemaType<APIResponse<FindProjectsResult>> = {
  $id: 'findProjectsResult',
  type: 'object',
  properties: {
    data: {
      type: 'object',
      properties: {
        isCompleteList: { type: 'boolean' },
        projects: {
          type: 'array',
          items: projectSchema,
        }
      },
      required: ['isCompleteList'],
    }
  },
  required: ['data'],
};

const projectWorkerSchema: JSONSchemaType<ProjectWorker> = {
  $id: 'projectWorker',
  type: 'object',
  properties: {
    id: { type: 'string' },
    name: { type: 'string' },
  },
  required: ['id', 'name'],
};

const projectWorkersSchema: JSONSchemaType<APIListResponse<ProjectWorker>> = {
  $id: 'projectWorkers',
  type: 'object',
  properties: {
    data: {
      type: 'array',
      items: projectWorkerSchema,
    }
  },
  required: ['data'],
};

const numberMinMaxSchema: JSONSchemaType<MinMax<number>> = {
  $id: 'numberMinMax',
  type: 'object',
  nullable: true,
  properties: {
    min: { type: 'number', nullable: true },
    max: { type: 'number', nullable: true },
  }
};

const projectQuerySchema: JSONSchemaType<ProjectQuery> =
{
  $id: 'projectQuery',
  type: 'object',
  properties: {
    fullTextSearch: { type: 'string', nullable: true },
    customer: { type: 'string', nullable: true },
    projectOwner: { type: 'string', nullable: true },
    salesperson: { type: 'string', nullable: true },
    businessUnit: { type: 'string', nullable: true },
    scopeInManDays: { $ref: 'numberMinMax' },
    yearsPassed: { $ref: 'numberMinMax' },
    orderBy: { type: 'string', nullable: true },
    ascending: { type: 'boolean', nullable: true },
  },
};

const projectReferenceSchema: JSONSchemaType<ProjectReference> = {
  $id: 'projectReference',
  type: 'object',
  properties: {
    key: { type: 'string' },
    languageCode: { type: 'string' },
    content: { type: 'string' },
    translationAvailable: { type: 'boolean' }, // if all references are translationAvailable = false -> disable 'translate all' button
    isApproved: { type: 'boolean' },
  },
  required: ['key', 'languageCode', 'content'],
};

const projectReferencesSchema: JSONSchemaType<APIResponse<ProjectReferences>> = {
  $id: 'projectReferences',
  type: 'object',
  properties: {
    data: {
      type: 'object',
      properties: {
        project: projectSchema,
        references: {
          type: 'array',
          items: projectReferenceSchema,
        }
      },
      required: ['project', 'references']
    }
  },
  required: ['data'],
};

const businessUnitSchema: JSONSchemaType<BusinessUnit> = {
  $id: 'businessUnit',
  type: 'object',
  properties: {
    id: { type: 'string' },
    name: { type: 'string' },
  },
  required: ['name'],
};

const businessUnitsSchema: JSONSchemaType<APIListResponse<BusinessUnit>> = {
  $id: 'businessUnits',
  type: 'object',
  properties: {
    data: {
      type: 'array',
      items: businessUnitSchema,
    }
  },
  required: ['data'],
};


export const schemaValidator = new Ajv({
  schemas: [
    projectReferenceSchema,
    projectReferencesSchema,
    projectWorkerSchema,
    projectWorkersSchema,
    numberMinMaxSchema,
    projectQuerySchema,
    referenceStatusSchema,
  ],
});

export const validators = {
  referenceStatusValidator: (): ValidateFunction<ReferenceStatus> =>
    schemaValidator.compile(referenceStatusSchema),
  projectValidator: (): ValidateFunction<Project> =>
    schemaValidator.compile(projectSchema),
  projectsValidator: (): ValidateFunction<APIResponse<FindProjectsResult>> =>
    schemaValidator.compile(findProjectsSchema),
  projectWorkerValidator: (): ValidateFunction<ProjectWorker> =>
    schemaValidator.compile(projectWorkerSchema),
  projectWorkersValidator: (): ValidateFunction<APIListResponse<ProjectWorker>> =>
    schemaValidator.compile(projectWorkersSchema),
  projectReferenceValidator: (): ValidateFunction<ProjectReference> =>
    schemaValidator.compile(projectReferenceSchema),
  projectReferencesValidator: (): ValidateFunction<APIResponse<ProjectReferences>> =>
    schemaValidator.compile(projectReferencesSchema),
  projectQueryValidator:
    (): ValidateFunction<ProjectQuery> =>
      schemaValidator.compile(projectQuerySchema),
  businessUnitsValidator: (): ValidateFunction<APIListResponse<BusinessUnit>> =>
    schemaValidator.compile(businessUnitsSchema),
};
