import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Project } from '../../api';

const initialState = {
  value: [] as Project[],
};

export const projectSummariesSlice = createSlice({
  name: 'projectSummaries',
  initialState,
  reducers: {
    setProjectSummaries: (
      state: typeof initialState,
      action: PayloadAction<Project[]>,
    ) => {
      state.value = action.payload;
    },
  },
});

export const { setProjectSummaries } = projectSummariesSlice.actions;

export default projectSummariesSlice.reducer;
