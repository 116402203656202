import React from 'react';
import {
  Button,
  ButtonProps,
  Dialog,
  DialogContent,
  DialogProps,
  DialogTitle,
  Stack,
  Typography,
} from '@mui/material';

interface Interaction extends ButtonProps {
  text: string;
}

interface Props extends DialogProps {
  description: string;
  interactions: Interaction[];
  title: string;
}

export const InteractionDialog: React.FC<Props> = (props) => {
  const { description, interactions, title, ...rest } = props;

  return (
    <Dialog {...rest}>
      <DialogTitle>{title}</DialogTitle>

      <DialogContent>
        <Stack gap={4}>
          <Typography>{description}</Typography>

          <Stack direction="row" justifyContent="space-between" gap={2}>
            {interactions.map(({ text, ...rest }) => (
              <Button key={`interaction-${text}`} {...rest}>
                {text}
              </Button>
            ))}
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};
