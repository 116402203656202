import { createApi } from '@reduxjs/toolkit/query/react';
import { BaseQueryFn, FetchArgs, FetchBaseQueryError, fetchBaseQuery } from '@reduxjs/toolkit/query';
import {
  ProjectWorker,
  ProjectQuery,
  ProjectReference,
  ProjectReferences,
  APIResponse,
  APIListResponse,
  FindProjectsResult,
  Project,
  BusinessUnit,
} from '../../api';
import { schemaValidator, validators } from '../../api/runtime';
import { ValidateFunction } from 'ajv';
import 'dotenv/config';
import { msalInstance } from '../..';

const WORKERS_TAG = 'ProjectWorkers' as const;
const BUSINESS_UNITS_TAG = 'BusinessUnits' as const;
const PROJECTS_TAG = 'Projects' as const;
const PROJECT_REFERENCES_TAG = 'ProjectReferences' as const;
const LIST = 'LIST';

const baseQueryWithAuth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOpts) => {
  const baseQuery = fetchBaseQuery({
    prepareHeaders: async (headers: Headers) => {
      headers.append('Accept', 'application/json');

      if (process.env.CONFIG_REQUIRE_AUTH === "false") {
        return
      }
      const accounts = msalInstance.getAllAccounts()
      if (accounts.length === 0) {
        // TODO: Handle this situation better? Does it actually happen outside of manually deleting session storage though?
        console.log("Msal instance has no accounts, login needed")
        return
      }
      const accessTokenRequest = {
        scopes: ["api://a608d2e8-592d-4e58-87fe-ca186d02fd18/ReferenceTool.Use"],
        account: accounts[0],
      }
      let at = ''
      await msalInstance.acquireTokenSilent(accessTokenRequest).then(
        (accessTokenResponse) => {
          at = accessTokenResponse.accessToken
        }).catch(function (error) {
          console.log(error)
        })
      headers.append('Authorization', "Bearer " + at)
    },
    baseUrl: process.env.API_URL?.replace(/\/$/, '') || '/api',
  })
  const result = await baseQuery(args, api, extraOpts)
  return result
}

// TODO: Fix api being called before init when reloading frontend after script changes
// Error description: Uncaught (in promise) ReferenceError: can't access lexical declaration 'api' before initialization
const refToolApi = createApi({
  reducerPath: 'refToolApi',
  baseQuery: baseQueryWithAuth,
  tagTypes: [
    WORKERS_TAG,
    PROJECTS_TAG,
    PROJECT_REFERENCES_TAG,
    BUSINESS_UNITS_TAG,
  ],
  endpoints: (builder) => ({
    getProjectWorkers: builder.query<APIListResponse<ProjectWorker>, void>({
      query: () => '1/projectWorkers',
      transformResponse: (data: unknown): APIListResponse<ProjectWorker> => {
        const validate = validators.projectWorkersValidator();

        if (!validate(data)) {
          throw newValidationError(validate);
        }
        return data;
      },
      providesTags: (result) =>
        result
          ? [
            ...result.data.map(({ id }) => ({ type: WORKERS_TAG, id })),
            { type: WORKERS_TAG, id: LIST },
          ]
          : [{ type: WORKERS_TAG, id: LIST }],
    }),
    getProjects: builder.query<
      APIResponse<FindProjectsResult>,
      ProjectQuery
    >({
      query: (opts: ProjectQuery) => {
        return `1/projects?${projectSearchStr(opts)}`
      },
      transformResponse: (data: unknown): APIResponse<FindProjectsResult> => {
        const validate = validators.projectsValidator();

        if (!validate(data)) {
          throw newValidationError(validate);
        }
        return data;
      },
      providesTags: (result) =>
        result
          ? [
            ...result.data.projects.map(({ id }) => ({
              type: PROJECTS_TAG,
              id: id,
            })),
            { type: PROJECTS_TAG, id: LIST },
          ]
          : [{ type: PROJECTS_TAG, id: LIST }],
    }),

    getProjectReferences: builder.query<
      APIResponse<ProjectReferences>,
      {
        project: string;
        translate?: string;
      }
    >({
      query: ({ project, translate }) =>
        `1/projects/${project}${translate ? `?translate=${translate}` : ''}`,
      transformResponse: (data: unknown): APIResponse<ProjectReferences> => {
        const validate = validators.projectReferencesValidator();

        if (!validate(data)) {
          throw newValidationError(validate);
        }
        return data;
      },
      providesTags: (result) => {
        if (!result) {
          return [{ type: PROJECT_REFERENCES_TAG, id: LIST }]
        }
        const { project, references } = result.data
        return [
          ...references.map(({ key, languageCode }) => ({
            type: PROJECT_REFERENCES_TAG,
            id: project.id + "." + key + "." + languageCode,
          })),
          { type: PROJECT_REFERENCES_TAG, id: LIST },
        ]
      }
    }),

    saveProjectReference: builder.mutation<
      string,
      { projectId: string; references: ProjectReference[] }
    >({
      query: ({ projectId: project, references }) => ({
        url: `1/projects/${project}/references`,
        method: 'PUT',
        body: references,
      }),
      invalidatesTags: (_, __, result) => {
        if (!result) {
          return [{ type: PROJECT_REFERENCES_TAG, id: LIST }]
        }
        const { projectId, references } = result
        return [
          ...references.map(({ key, languageCode }) => ({
            type: PROJECT_REFERENCES_TAG,
            id: projectId + "." + key + "." + languageCode,
          })),
          { type: PROJECT_REFERENCES_TAG, id: LIST },
        ]
      }
    }),

    saveProject: builder.mutation<
      string,
      { project: Project }
    >({
      query: ({ project }) => ({
        url: `1/projects/${project.id}`,
        method: 'PUT',
        body: project,
      }),
      invalidatesTags: (_, __, result) => {
        if (!result) {
          return []
        }
        const { project } = result
        return [{
          type: PROJECTS_TAG,
          id: project.id,
        },
        { type: PROJECTS_TAG, id: LIST }
        ]
      }
    }),
    getBusinessUnits: builder.query<APIListResponse<BusinessUnit>, void>({
      query: () => '1/businessUnits',
      transformResponse: (data: unknown): APIListResponse<BusinessUnit> => {
        const validate = validators.businessUnitsValidator();
        if (!validate(data)) {
          throw newValidationError(validate);
        }
        return data;
      },
      providesTags: (result) =>
        result
          ? [
            ...result.data.map(({ name }) => ({ type: BUSINESS_UNITS_TAG, name })),
            { type: BUSINESS_UNITS_TAG, id: LIST },
          ]
          : [{ type: BUSINESS_UNITS_TAG, id: LIST }],
    }),
  }),
});

const newValidationError = (validate: ValidateFunction): Error => {
  return new Error(
    `validation failure: ${schemaValidator.errorsText(validate.errors)}`,
  );
};

const projectSearchStr = (opts: ProjectQuery) => {
  const projectSearchData = {
    search: opts.fullTextSearch || '',
    scopeMin: opts.scopeInManDays?.min?.toString() || '',
    scopeMax: opts.scopeInManDays?.max?.toString() || '',
    yearsPassedMin: opts.yearsPassed?.min?.toString() || '',
    yearsPassedMax: opts.yearsPassed?.max?.toString() || '',
    customer: opts.customer || '',
    salesperson: opts.salesperson || '',
    projectOwner: opts.projectOwner || '',
    businessUnit: opts.businessUnit || '',
    orderBy: opts.orderBy || '',
    ascending: opts.ascending?.toString() || '',
  }
  const searchParams = new URLSearchParams(projectSearchData)
  return searchParams.toString();
};

export const api = refToolApi;

export const {
  useGetProjectsQuery,
  useGetProjectWorkersQuery,
  useGetProjectReferencesQuery,
  useSaveProjectMutation,
  useSaveProjectReferenceMutation,
  useLazyGetProjectReferencesQuery,
  useGetBusinessUnitsQuery,
} = api;
