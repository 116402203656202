import React from 'react';
import { DialogProps } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { InteractionDialog } from '../InteractionDialog';

interface Props extends DialogProps {
  closeDialog: () => void;
  resetForm: () => void;
}

export const DiscardDialog: React.FC<Props> = (props) => {
  const { closeDialog, resetForm, ...rest } = props;
  const { t } = useTranslation();

  const handleDiscard = () => {
    resetForm();
    closeDialog();
  };

  return (
    <InteractionDialog
      title={t('references.edit.unsavedChanges.title')}
      description={t('references.edit.discardChanges.description')}
      interactions={[
        {
          color: 'success',
          onClick: closeDialog,
          text: t('references.edit.unsavedChanges.continueEditing'),
        },
        {
          color: 'warning',
          onClick: handleDiscard,
          text: t('references.edit.discardChanges.discard'),
        },
      ]}
      {...rest}
    />
  );
};
