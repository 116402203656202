import { useEffect, useState } from 'react';

export const useSelection = <T>(initialSelection?: T[]) => {
  const [selected, setSelected] = useState<Set<T>>(new Set());
  const [selectedArr, setSelectedArr] = useState<readonly T[]>([]);

  useEffect(() => {
    setSelected(new Set(initialSelection?.slice() || []));
  }, [initialSelection]);

  useEffect(() => {
    setSelectedArr([...selected.values()]);
  }, [selected]);

  const changeSelection = (elem: T) => {
    const selectedIndex = selected.has(elem);

    if (!selectedIndex) {
      setSelected(new Set([...selected, elem]));
    } else {
      selected.delete(elem);
      setSelected(new Set([...selected]));
    }
  };

  const updateSelection = (selection: readonly T[]) => {
    setSelected(new Set([...selection]));
  };

  const isSelected = (elem: T) => selected.has(elem);

  return {
    changeSelection,
    isSelected,
    selected: selectedArr,
    setSelected: updateSelection,
  };
};
