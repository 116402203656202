const translation = {
  selected: 'vald',
  copyToClipboardButton: 'Kopierad till urklipp',
  enableLanguageSelector: 'Översätt till ett annat språk',
  languageSelector: {
    languages: 'Språk',
    selectLanguage: 'Välj språk',
  },
  signIn: 'Sign In',
  signOut: 'Sign Out',
  signInPrompt: 'You are not signed in',
  save: 'Spara',
  cancel: 'Avbryt',
  close: 'Stäng',
  edit: 'Redigera',
  cv: {
    downloadCV: 'Ladda ner CV',
    filename: 'CV_referenser_{{id}}.docx',
  },
  download: 'Ladda ner',
  downloadStarted: 'Nedladdning påbörjad',
  includeSeveraAndMajakkaIDs: 'Inkludera Severa och Majakka IDs',
  localizationLanguages: {
    fi: "Finska",
    en: "Engelska",
    sv: "Svenska",
    da: "Danska"
  },
  errors: {
    download: 'Fel vid nedladdning av fil: {{error}}',
    fetchPersonCards: 'Fel vid hämtning av personer: {{error}}',
    fetchProject: 'Fel vid hämtning av projekt {{error}}',
    fetchProjectSummaries: 'Fel vid hämtning av projektöversikter: {{error}}',
    fetchReference: 'Fel vid hämtning av en referens: {{error}}',
    saveProject: 'Error saving project: {{error}}',
    saveReference: 'Fel vid spara referens: {{error}}',
  },
  tooltips: {
    projects: {
      referenceStatus: 'Shows in which languages the ended project has all approved references',
      scope: 'Scope of project in euros',
    },
  },
  nav: {
    cv: 'Anställds CV',
    heading: 'NHG Referensverktyg',
    references: 'Export och redigering av referenser',
    refEdit: 'Redigera referens',
  },
  projects: {
    downloadForPowerpoint: 'Ladda ner för Powerpoint',
    downloadForWord: 'Ladda ner för Word',
    ended: 'Avslutad',
    label: 'Projekt',
    name: 'Namn',
    started: 'Startad',
    scope: '€',
    edit: {
      saveSuccess: 'Project details successfully changed.'
    },
    search: {
      customer: 'Kund',
      fullTextSearch: 'Sök i fulltext (eller Severa #)',
      heading: 'Sök efter projekt',
      projectOwner: 'Projektledare',
      salesperson: 'Select salesperson',
      businessUnit: 'Select business unit',
      projectWorker: 'Välj projektarbetare',
      scopeInManDays: 'Omfattning (mansdagar)',
      timeframeYearsAgo: 'Tidram (år sedan)',
    },
  },
  references: {
    edit: {
      additionalInformation: {
        label: 'NHG-teamet',
        placeholder: 'NHG:s kontaktpersoner / inblandade personer',
      },
      customerContacts: {
        label: 'Kundkontakt(er)',
        placeholder: 'Namn och e-post',
      },
      cvReference: {
        label: 'CV Referens',
        placeholder: 'Kompakt beskrivning för CV och erbjudanden, undvik punktlistor. Beskriv tydligt projekt, mål och resultat utan att introducera nya frågor som inte ingår i den långa referensen.',
      },
      discardChanges: {
        description: 'Vill du kasta ändringarna?',
        discard: 'Kasta ändringar',
      },
      duration: {
        label: 'Varaktighet',
        placeholder: '',
      },
      execution: {
        label: 'Utförande',
        placeholder: 'Beskriv projektets struktur - faser och delprojekt, t.ex., “Analys, Utveckling, Implementering, Utvärdering.”',
      },
      introduction: {
        label: 'Introduktion',
        placeholder: 'Kort projektintroduktion, t.ex., “SAKARI: Gemensamt projekt finansierat av Social- och hälsovårdsministeriet för att förbättra äldreomsorgstjänster.”',
      },
      methods: {
        label: 'Metoder',
        placeholder: 'Lista ALLA använda metoder. Kan också ingå som en del av Utförande.',
      },
      objectives: {
        label: 'Mål',
        placeholder: 'Beskriv projektets mål konkret, t.ex., “Utveckla och implementera en anpassningsbar, kundorienterad hemtjänstmodell.”',
      },
      results: {
        label: 'Resultat',
        placeholder: 'Beskriv konkreta resultat, åtgärder och effekter, eventuellt uppdelat i faser, t.ex., “Porvoos stad ökade effektiv arbetstid med 9 procentenheter och minskade användningen av vikarier med 37 procent. Arbetsbok skapad för modellimplementering finns tillgänglig på www.xxx.fi.”',
      },
      saveSuccess: 'Referens sparad',
      translationSuccess: "Översättningar skapades framgångsrikt",
      scope: {
        label: 'Omfattning',
        placeholder: '',
      },
      unsavedChanges: {
        continueEditing: 'Fortsätt redigera',
        description:
          'Vill du spara dina ändringar innan du stänger redigeraren?',
        descriptionLanguageChange: 'Vill du spara dina ändringar?',
        discardChanges: 'Kasta ändringar och avsluta',
        discardChangesAndChangeLanguage: 'Kassera ändringar',
        saveAndExit: 'Spara och avsluta',
        title: 'Du har osparade ändringar',
        save: 'Spara ändringar',
      },
    },
    peopleSelector: {
      selectEmployee: 'Välj anställd',
    },
    icons: {
      hasReferences: 'Projektet har icke godkända referenser',
      hasCVReference: 'Projektet har inte en CV-referens',
    },
  }
};

export default translation;
