import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import en from './en';
import fi from './fi';
import sv from './sv';
import da from './da';

export type Translation = typeof en;
export const availableLanguages = [
  {id: 'en', language: 'English'},
  {id: 'fi', language: 'Finnish'},
  {id: 'sv', language: 'Swedish'},
  {id: 'da', language: 'Danish'},
]

i18n
  // detect user language
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  .init({
    debug: process.env.NODE_ENV === 'development',
    fallbackLng: 'en',
    lng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: en,
      },
      fi: {
        translation: fi,
      },
      sv: {
        translation: sv,
      },
      da: {
        translation: da,
      }
    },
  });

export default i18n;
