import { useState } from 'react';
import { IconButton, Snackbar } from '@mui/material';
import ContentCopy from '@mui/icons-material/ContentCopy'
import { useTranslation } from 'react-i18next';
import React from 'react';

export const CopyToClipboardButton = ({ projectId }) => {
    const [open, setOpen] = useState(false)
    const { t } = useTranslation();
    
    const handleClick = () => {
        setOpen(true)
        navigator.clipboard.writeText(projectId)
    }
    return (
        <>
            <IconButton onClick={handleClick} sx={{ padding: 0, paddingLeft: 1}}>
                <ContentCopy fontSize="small" sx={{ color: 'primary.main', padding: 0, margin: 0}}/>
            </IconButton>
            <Snackbar 
                message={t('copyToClipboardButton')}
                anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                autoHideDuration={2000}
                onClose={() => setOpen(false)}
                open={open}
            />
        </>
    )
}

export default CopyToClipboardButton;