import {
  Box,
  Checkbox,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography,
} from '@mui/material';
import React from 'react';
import { Data, HeadCell } from './types';
import { visuallyHidden } from '@mui/utils';
import { useTranslation } from 'react-i18next';
import { Order } from '../../hooks/useSortedData';

interface Props {
  selectionConfig?: {
    numSelected: number;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    rowCount: number;
  };
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data,
  ) => void;
  order: Order;
  orderBy?: string;
}

export const ProjectSummaryTableHead = (props: Props) => {
  const { selectionConfig, order, orderBy, onRequestSort } = props;
  const { t } = useTranslation();

  const headCells: readonly HeadCell[] = [
    {
      id: 'name',
      label: t('projects.name'),
      numeric: false,
    },
    {
      id: 'referenceStatus',
      label: 'Languages',
      numeric: false,
      width: '13%',
      tooltip: t('tooltips.projects.referenceStatus'),
    },
    {
      id: 'scope',
      label: t('projects.scope'),
      numeric: false,
      width: '13%',
      tooltip: t('tooltips.projects.scope'),
    },
    {
      id: 'started',
      label: t('projects.started'),
      numeric: false,
      width: '13%',
    },
    {
      id: 'ended',
      label: t('projects.ended'),
      numeric: false,
      width: '13%',
    },
  ];

  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {selectionConfig && (
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              indeterminate={
                selectionConfig.numSelected > 0 &&
                selectionConfig.numSelected < selectionConfig.rowCount
              }
              checked={
                selectionConfig.rowCount > 0 &&
                selectionConfig.numSelected === selectionConfig.rowCount
              }
              onChange={selectionConfig.onSelectAllClick}
              inputProps={{
                'aria-label': 'select all projects',
              }}
            />
          </TableCell>
        )}

        {headCells.map((headCell) => (
          <Tooltip 
            key={headCell.id}
            title={
              headCell.tooltip ?
                <React.Fragment>
                  <Typography color="inherit" fontSize='caption.fontSize' textAlign='center'>{headCell.tooltip}</Typography>
                </React.Fragment>
              : ''}
            placement='top'
            slotProps={{
              popper: {
                modifiers: [
                  {
                    name: 'offset',
                    options: {
                      offset: [0, -14],
                    },
                  },
                ],
              },
            }} 
          >

            <TableCell
              key={headCell.id}
              align={headCell.numeric ? 'right' : 'left'}
              padding={'normal'}
              width={headCell.width}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
                disabled={headCell.id === "referenceStatus"}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                <Box
                  component="span"
                  display="flex"
                  flexDirection="column"
                  sx={visuallyHidden}
                >
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
              </TableSortLabel>
            </TableCell>
          </Tooltip>
        ))}
      </TableRow>
    </TableHead>
  );
};
