import { useEffect, useState } from 'react';
import { Data } from '../types';

import {
  sortObjByDateField,
  sortObjByNumberField,
  sortObjByStringField,
} from '../utils/sort';

export type Order = 'asc' | 'desc';

const sorterForData = (orderBy: keyof Data): ((a: Data, b: Data) => number) => {
  switch (orderBy) {
    case 'ended':
    case 'started':
      return sortObjByDateField<Data, typeof orderBy>(orderBy);
    case 'id':
    case 'name':
      return sortObjByStringField<Data, typeof orderBy>(orderBy);

    case 'invoicing':
    case 'scope':
      return sortObjByNumberField<Data, typeof orderBy>(orderBy);

    default:
      return (_: Data, __: Data) => 0;
  }
};

const sortData = (rows: Data[], order: Order, orderBy: keyof Data): Data[] => {
  const sorter = sorterForData(orderBy);

  return rows.sort((a: Data, b: Data) =>
    order === 'asc' ? sorter(a, b) : -sorter(a, b),
  );
};

export const useSortedData = (
  data: Data[],
  order: Order,
  orderBy?: keyof Data,
) => {
  const [sortedData, setSortedData] = useState<Data[]>([]);

  useEffect(() => {
    if (!orderBy) {
      setSortedData(data);

    } else {
      const newSorted = sortData([...data], order, orderBy); 
      
      setSortedData(newSorted);
    }
  }, [data, order, orderBy]);

  return { sortedData };
};
