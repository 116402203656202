import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { ProjectQuery } from '../../api';
import { validators } from '../../api/runtime';

const initialState: ProjectQuery = (() => {
  const initState = {
    fullTextSearch: '',
    customer: '',
    salesperson: '',
    businessUnit: '',
    projectOwner: '',
    scopeInManDays: { min: 0, max: 1000 },
    yearsPassed: { min: 0, max: 10 },
  };

  const loadedState = loadSearchParamsFromURL()
  const validate = validators.projectQueryValidator();
  return validate(loadedState) ? loadedState : initState
})();

export const projectSummarySearchSlice = createSlice({
  name: 'projectSummarySearch',
  initialState,
  reducers: {
    setProjectSummarySearchOpts: (
      state: ProjectQuery,
      action: PayloadAction<ProjectQuery>,
    ) => {
      const {
        fullTextSearch,
        customer,
        salesperson,
        projectOwner,
        businessUnit,
        scopeInManDays,
        yearsPassed,
      } = action.payload;

      state.fullTextSearch = fullTextSearch;
      state.customer = customer;
      state.salesperson = salesperson
      state.projectOwner = projectOwner;
      state.businessUnit = businessUnit;
      state.scopeInManDays = scopeInManDays;
      state.yearsPassed = yearsPassed;

      const urlParams = getSearchURLParams(state)
      history.replaceState(null, "", `?${urlParams.toString()}`);
    },
  },
});

export const { setProjectSummarySearchOpts } =
  projectSummarySearchSlice.actions;

export default projectSummarySearchSlice.reducer;

function loadSearchParamsFromURL() {
  const queryString = window.location.search;
  console.log(queryString);
  const urlParams = new URLSearchParams(queryString)

  if (!urlParams) {
    return undefined;
  }

  const loadedState: ProjectQuery = {
    fullTextSearch: urlParams.get('fullTextSearch') as string || '',
    customer: urlParams.get('customer') as string || '',
    salesperson: urlParams.get('salesperson') as string || '',
    projectOwner: urlParams.get('projectOwner') as string || '',
    businessUnit: urlParams.get('businessUnit') as string || '',
  }

  const scopeMin = parseInt(urlParams.get('scopeMin') as string)
  const scopeMax = parseInt(urlParams.get('scopeMax') as string)
  if (!isNaN(scopeMin) && !isNaN(scopeMax)) {
    loadedState.scopeInManDays = { min: scopeMin, max: scopeMax }
  }
  const yearsPassedMin = parseInt(urlParams.get('yearsPassedMin') as string)
  const yearsPassedMax = parseInt(urlParams.get('yearsPassedMax') as string)
  if (!isNaN(yearsPassedMin) && !isNaN(yearsPassedMax)) {
    loadedState.yearsPassed = { min: yearsPassedMin, max: yearsPassedMax }
  }
  return loadedState
}

export function getSearchURLParams(state: ProjectQuery) {
  const urlParams = new URLSearchParams()

  state.fullTextSearch && urlParams.set('fullTextSearch', state.fullTextSearch)
  state.customer && urlParams.set('customer', state.customer)
  state.salesperson && urlParams.set('salesperson', state.salesperson)
  state.projectOwner && urlParams.set('projectOwner', state.projectOwner)
  state.businessUnit && urlParams.set('businessUnit', state.businessUnit)

  const scopeMin = state.scopeInManDays?.min?.toString()
  const scopeMax = state.scopeInManDays?.max?.toString()
  if (scopeMin && scopeMax) {
    urlParams.set('scopeMin', scopeMin)
    urlParams.set('scopeMax', scopeMax)
  }

  const yearsPassedMin = state.yearsPassed?.min?.toString()
  const yearsPassedMax = state.yearsPassed?.max?.toString()
  if (yearsPassedMin && yearsPassedMax) {
    urlParams.set('yearsPassedMin', yearsPassedMin)
    urlParams.set('yearsPassedMax', yearsPassedMax)
  }

  return urlParams
}

