import React, { useMemo } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Stack,
  SxProps,
  Theme,
  Tooltip,
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';

interface Props {
  handleCancel: () => void;
  handleClose: () => void;
  handleSave: () => void;
  isDirty: boolean;
  isLoading: boolean;
}

export const EditorTools: React.FC<Props> = (props) => {
  const {
    handleCancel,
    handleClose,
    handleSave,
    isDirty,
    isLoading,
  } = props;
  const { t } = useTranslation();

  const buttonSx: SxProps<Theme> = useMemo(
    () => ({
      borderRadius: '50%',
      height: '4.5em',
      width: '3em',
      visibility: isLoading ? 'hidden' : 'visible',
    }),
    [isLoading],
  );

  return (
    <Stack direction="row" gap={1} sx={{ px: 1, marginTop: -1 }}>
      <Stack flex={1}>
        <Tooltip title={t('cancel')}>
          <Box component="span">
            <Button
              disabled={!isDirty}
              onClick={handleCancel}
              sx={buttonSx}
              variant="text"
              color="warning"
            >
              <CancelIcon />
            </Button>
          </Box>
        </Tooltip>
      </Stack>


      <Stack alignItems="center" justifyContent="center" flex={1}>
        {isLoading ? (
          <Stack width="4.5em" alignItems="center" justifyContent="center">
            <CircularProgress sx={{ width: '3em' }} />
          </Stack>
        ) : (
          <Tooltip title={t('save')}>
            <Box component="span">
              <Button
                onClick={handleSave}
                variant={'contained'}
                sx={buttonSx}
                disabled={!isDirty}
                color={'primary'}
              >
                {<SaveIcon />}
              </Button>
            </Box>
          </Tooltip>
        )}
      </Stack>

      <Stack flex={1}>
        <Tooltip title={t('close')}>
          <Box component="span">
            <Button
              onClick={handleClose}
              sx={buttonSx}
              variant="text"
              color="error"
            >
              <CloseIcon />
            </Button>
          </Box>
        </Tooltip>
      </Stack>
    </Stack>
  );
};
