export const sortObjByDateField =
  <T extends { [key in K]?: Date }, K extends keyof T>(
    orderBy: K,
    sortUndefinedToEnd = true,
  ) =>
  (a: T, b: T): number => {
    const dateA = a[orderBy];
    const dateB = b[orderBy];

    if (!dateA && !dateB) {
      return 0;
    }

    if (!dateA) {
      return sortUndefinedToEnd ? 1 : -1;
    }

    if (!dateB) {
      return sortUndefinedToEnd ? -1 : 1;
    }

    return dateA.getTime() - dateB.getTime();
  };

export const sortObjByStringField =
  <T extends { [key in K]: string }, K extends keyof T>(
    orderBy: K,
    sortEmptyToEnd = true,
  ) =>
  (a: T, b: T): number => {
    const strA = a[orderBy];
    const strB = b[orderBy];

    if (!strA && !strB) {
      return 0;
    }

    if (!strA) {
      return sortEmptyToEnd ? 1 : -1;
    }

    if (!strB) {
      return sortEmptyToEnd ? -1 : 1;
    }

    return a[orderBy].localeCompare(b[orderBy]);
  };

export const sortObjByNumberField =
  <T extends { [key in K]?: number }, K extends keyof T>(
    orderBy: K,
    sortUndefinedToEnd = true,
  ) =>
  (a: T, b: T): number => {
    const numA = a[orderBy];
    const numB = b[orderBy];

    if (!numA && !numB) {
      return 0;
    }

    if (!numA) {
      return sortUndefinedToEnd ? 1 : -1;
    }

    if (!numB) {
      return sortUndefinedToEnd ? -1 : 1;
    }

    return numA - numB;
  };
