import React, { useEffect, useState } from 'react';
import { Checkbox, Stack, TextField, Typography } from '@mui/material';
import { ProjectQuery } from '../../api';
import { useTranslation } from 'react-i18next';
import { LabeledSlider } from '../form/LabeledSlider';
import { useDispatch, useSelector } from 'react-redux';
import { setProjectSummarySearchOpts } from '../../store/features/projectSummarySearchSlice';
import { RootState } from '../../store/store';
import { PeopleSelector } from '../form/PeopleSelector';
import { BusinessUnitSelector } from '../form/BusinessUnitSelector';

export const ProjectSearch: React.FC = () => {
  const dispatch = useDispatch();
  const search = useSelector(
    (state: RootState) => state.projectSummarySearchOpts,
  );
  const { t } = useTranslation();

  const { scopeInManDays, yearsPassed } = search;

  const [bufferedSearch, setBufferedSearch] = useState(search);
  const [scopeEnabled, setScopeEnabled] = React.useState(scopeInManDays != undefined);
  const handleScopeEnabledChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setScopeEnabled(event.target.checked);
  };
  const [scope, setScope] = useState<number[]>([
    0, 1000
  ]);

  const [timeframeEnabled, setTimeframeEnabled] = React.useState(yearsPassed != undefined);
  const handleTimeframeEnabledChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTimeframeEnabled(event.target.checked);
  };
  const [timeframe, setTimeframe] = useState<number[]>([0, 10]);

  const [businessUnit, setBusinessUnit] = useState<string | undefined>(
    search.businessUnit,
  );

  const [projectOwner, setProjectOwner] = useState<string | undefined>(
    search.projectOwner,
  );

  const [salesperson, setSalesperson] = useState<string | undefined>(
    search.salesperson,
  );

  useEffect(() => {
    if (scopeInManDays != undefined
      && scopeInManDays.min != undefined
      && scopeInManDays.max != undefined) {
      setScope([scopeInManDays.min, scopeInManDays.max])
    }
    if (yearsPassed != undefined
      && yearsPassed.min != undefined
      && yearsPassed.max != undefined) {
      setTimeframe([yearsPassed.min, yearsPassed.max])
    }
  }, []);

  useEffect(() => {
    if (scope.length === 2 && scopeEnabled) {
      const [min, max] = scope;

      setBufferedSearch({
        ...bufferedSearch,
        scopeInManDays: { min, max },
      });
    } else {
      setBufferedSearch({
        ...bufferedSearch,
        scopeInManDays: undefined,
      });
    }
  }, [scope, scopeEnabled]);

  useEffect(() => {
    if (timeframe.length === 2 && timeframeEnabled) {
      const [min, max] = timeframe;

      setBufferedSearch({
        ...bufferedSearch,
        yearsPassed: { min, max },
      });
    } else {
      setBufferedSearch({
        ...bufferedSearch,
        yearsPassed: undefined,
      });
    }
  }, [timeframe, timeframeEnabled]);

  useEffect(() => {
    setBufferedSearch({
      ...bufferedSearch,
      projectOwner: projectOwner,
    });
  }, [projectOwner]);

  useEffect(() => {
    setBufferedSearch({
      ...bufferedSearch,
      salesperson: salesperson,
    });
  }, [salesperson]);

  useEffect(() => {
    setBufferedSearch({
      ...bufferedSearch,
      businessUnit: businessUnit,
    });
  }, [businessUnit]);

  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(setProjectSummarySearchOpts(bufferedSearch));
    }, 250);

    return () => clearTimeout(timer);
  }, [bufferedSearch]);

  const changeStringHandler =
    (field: keyof ProjectQuery) =>
      (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setBufferedSearch({
          ...bufferedSearch,
          [field]: event.currentTarget.value,
        });
      };

  return (
    <Stack gap={4} sx={{ marginTop: 2 }}>
      <Typography variant="h6">{t('projects.search.heading')}</Typography>

      <TextField
        label={t('projects.search.fullTextSearch')}
        onChange={changeStringHandler('fullTextSearch')}
        variant="standard"
        value={bufferedSearch.fullTextSearch}
      />

      <TextField
        label={t('projects.search.customer')}
        onChange={changeStringHandler('customer')}
        variant="standard"
        value={bufferedSearch.customer}
      />

      <BusinessUnitSelector
        onChangeSelectedId={setBusinessUnit}
        label={t('projects.search.businessUnit')}
        id={businessUnit}
        isRequired={false}
      />
      <PeopleSelector
        onChangeSelectedId={setProjectOwner}
        label={t('projects.search.projectWorker')}
        id={projectOwner}
        isRequired={false}
      />
      <PeopleSelector
        onChangeSelectedId={setSalesperson}
        label={t('projects.search.salesperson')}
        id={salesperson}
        isRequired={false}
      />
      <Stack direction="row" gap={2} paddingRight={2}>
        <Checkbox
          checked={scopeEnabled}
          onChange={handleScopeEnabledChanged}
        />
        <LabeledSlider
          disabled={!scopeEnabled}
          label={`${t('projects.search.scopeInManDays')}: ${scope[0]} - ${scope[1]
            }`}
          max={1000}
          min={0}
          val={scope}
          setVal={setScope}
        />
      </Stack>
      <Stack direction="row" gap={2} paddingRight={2}>
        <Checkbox
          checked={timeframeEnabled}
          onChange={handleTimeframeEnabledChanged}
        />
        <LabeledSlider
          disabled={!timeframeEnabled}
          label={`${t('projects.search.timeframeYearsAgo')}: ${timeframe[0]} - ${timeframe[1]
            }`}
          max={10}
          min={0}
          val={timeframe}
          setVal={setTimeframe}
        />
      </Stack>
    </Stack>
  );
};
