import Button from '@mui/material/Button';
import { useMsal } from '@azure/msal-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const SignOutButton = () => {
    const { t } = useTranslation();
    const { instance } = useMsal();
    const authEnabled = process.env.CONFIG_REQUIRE_AUTH !== "false"
    const handleSignOut = () => {
        instance.logoutRedirect();
    }
    return (
        authEnabled && <Button onClick={handleSignOut}>{t('signOut')}</Button>
    )
};