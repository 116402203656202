import React, { useContext, useEffect, useState, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { ConfirmationDialog } from './ConfirmationDialog';
import { DiscardDialog } from './DiscardDialog';
import { ReferenceDialog } from './ReferenceDialog';
import { AlertContext } from '../../context/AlertContext';
import { useTranslation } from 'react-i18next';
import {
  useGetProjectReferencesQuery,
  useSaveProjectReferenceMutation,
} from '../../store/services/api';
import { ProjectReference } from '../../api';
import { useParams, useNavigate } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/query';
import { ChangeLanguageDialog } from './ChangeLanguageDialog';

export type FormValue = {
  content: string,
  isApproved: boolean
}

export type FormValues = {
  Introduction: FormValue,
  Objectives: FormValue,
  Execution: FormValue,
  Methods: FormValue,
  Results: FormValue,
  AdditionalInformation: FormValue,
  CVReference: FormValue,
  CustomerContacts: FormValue,
  Scope: FormValue,
  Duration: FormValue
}

const defaultFormValues = () => ({
  Introduction: {content: "", isApproved: false},
  Objectives: {content: "", isApproved: false},
  Execution: {content: "", isApproved: false},
  Methods: {content: "", isApproved: false},
  Results: {content: "", isApproved: false},
  AdditionalInformation: {content: "", isApproved: false},
  CVReference: {content: "", isApproved: false},
  CustomerContacts: {content: "", isApproved: false},
  Scope: {content: "", isApproved: false},
  Duration: {content: "", isApproved: false},
})

export interface Language {
  id: string,
  language: string,
}

// we use finnish as default (first) language for now, since most data is in finnish
export const selectableLanguage: Language[] = [
  { id: "fi", language: "Finnish" },
  { id: "en", language: "English" },
  { id: "sv", language: "Swedish" },
  { id: "da", language: "Danish" },
];

interface Props {
  onClose?: () => void;
}

export const ReferenceEditor: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate()
  const { onClose } = props;
  const { projectId } = useParams();

  const [selectedLanguage, setSelectedLanguage] = useState<{ id: string, language: string}>(
    selectableLanguage[0]
    )
  const [selectedLanguagePending, setSelectedLanguagePending] = useState<{ id: string, language: string}>(
    selectableLanguage[0]
  );

  const handleCloseChangeLanguageDialog = () => {
    navigate(`/references/${projectId}`)
    setSelectedLanguage(selectedLanguagePending)
  };
  
  // set translationActivator state to true for useGetProjectReferencesQuery call
  // sets state to false when switching to another reference language (Finnish, English, etc)
  const [translationActivator, setTranslationActivator] = useState(false);
  
  const {
    data: ProjectReferences,
    error: loadReferenceError,
    isFetching: loadReferenceIsFetching,
  } = useGetProjectReferencesQuery(projectId ? {
    project: projectId,
    translate: translationActivator ? selectedLanguage.id : undefined,
  } : skipToken);

  const { register, watch, control, handleSubmit, reset } = useForm({
    defaultValues: defaultFormValues()
  });
  
  const enableTranslateAllButton = () => {
    const found = ProjectReferences?.data.references.filter(ref => 
      (ref.translationAvailable && ref.languageCode === selectedLanguage.id)
    ) || []
    
    const isTranslationAvailable = found.length > 0

    if (isTranslationAvailable) {
      return true
    }
    return false
  };
  
  const resetForm = useCallback(() => {
    const formValues = defaultFormValues()
    if (ProjectReferences) {
      ProjectReferences.data.references.map((ref) => {
        if (ref.languageCode == selectedLanguage.id) {
          const formValue = formValues[ref.key as keyof FormValues]
          
          formValue.content = ref.content
          formValue.isApproved = ref.isApproved
        }
      })
    }
    reset(formValues)
  }, [ProjectReferences, selectedLanguage])

  useEffect(resetForm, [ProjectReferences, selectedLanguage])

  const [
    saveReference,
    { isLoading: saveReferenceIsLoading, error: saveReferenceError },
  ] = useSaveProjectReferenceMutation();
  const [isConfirmationDialogOpen, setIsConfirmationModalOpen] = useState(false);
  const [isDiscardDialogOpen, setIsDiscardDialogOpen] = useState(false);
  const [isChangeLanguageDialogOpen, setIsChangeLanguageDialogOpen] = useState(false);
  const { setMessage } = useContext(AlertContext);
  
  useEffect(() => {
    if (loadReferenceError) {
      setMessage(t('errors.fetchReference', { error: loadReferenceError }), 'error');
    }
  }, [loadReferenceError]);

  useEffect(() => {
    if (saveReferenceError) {
      setMessage(t('errors.saveReference', { error: saveReferenceError }), 'error');
    }
  }, [saveReferenceError]);

  // TODO: display error message if referenceFetching fails
  // TODO: display error message if there is no new references to translate
  // useEffect(() => {
  //   if (loadReferenceError) {
  //     console.log("Translate failed")
  //     setMessage('Translate failed', 'error')
  //   } else {
  //     console.log("Translated successfully")
  //     setMessage(t('references.edit.translationSuccess'));
  //   }
  // }, [loadReferenceIsFetching, loadReferenceError]);

  const handleSave = (data: FormValues) => {
    const saveRef = async () => {
      if (!projectId) {
        throw new Error("not project ID")
      }
      try {
        let newReferences: ProjectReference[] = []

        Object.entries(data).forEach(([key, value]) => {
          let newRef = { key: key, languageCode: selectedLanguage.id } as ProjectReference;
          if (ProjectReferences) {
            const foundRef = ProjectReferences.data.references.find(
              ref => ref.key == key && ref.languageCode == selectedLanguage.id)
            if (foundRef) {
              newRef = Object.assign({}, foundRef);
            }
          }
          newRef.content = value.content;
          newRef.isApproved = value.isApproved;

          newReferences = newReferences.concat(newRef)
        })

        await saveReference({
          projectId,
          references: newReferences
        });
        setMessage(t('references.edit.saveSuccess'));

      } catch (e) {
        setMessage(t('errors.saveReference', { error: e.message }), 'error');
      }
    };
    saveRef();
  };

  if (!ProjectReferences?.data.project) {
    return null;
  }
  return (
    <>
      <ReferenceDialog
        open={!!projectId}
        control={control}
        isLoading={saveReferenceIsLoading || loadReferenceIsFetching}
        project={ProjectReferences.data.project}
        selectedLanguage={selectedLanguage}
        onClose={onClose}
        onSave={handleSubmit((data) => handleSave(data))}
        openConfirmationDialog={() => setIsConfirmationModalOpen(true)}
        openDiscardDialog={() => setIsDiscardDialogOpen(true)}
        openChangeLanguageDialog={() => setIsChangeLanguageDialogOpen(true)}
        register={register}
        watch={watch}
        setSelectedLanguage={setSelectedLanguage}
        deactivateTranslate={() => setTranslationActivator(false)}
        activateTranslate={() => setTranslationActivator(true)}
        setSelectedLanguagePending={setSelectedLanguagePending}
		    enableTranslateAllButton={() => enableTranslateAllButton()}
      />

      <ConfirmationDialog
        open={isConfirmationDialogOpen}
        onClose={onClose}
        onSave={handleSubmit((data) => handleSave(data))}
        closeDialog={() => setIsConfirmationModalOpen(false)}
        resetForm={resetForm}
      />

      <DiscardDialog
        open={isDiscardDialogOpen}
        closeDialog={() => setIsDiscardDialogOpen(false)}
        resetForm={resetForm}
      />

      <ChangeLanguageDialog 
        open={isChangeLanguageDialogOpen}
        closeDialog={() => {
          setIsChangeLanguageDialogOpen(false);
        }}
        onClose={handleCloseChangeLanguageDialog}
        onSave={handleSubmit((data) => handleSave(data))}
        resetForm={resetForm}
      />
    </>
  );
};
