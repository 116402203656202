import { useEffect, useState } from 'react';

export const useVisibleData = <T>(
  data: T[],
  page: number,
  rowsPerPage: number,
) => {
  const [visibleData, setVisibleData] = useState<T[]>([]);

  useEffect(() => {
    const newVisibleData = data.slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage,
    );

    setVisibleData(newVisibleData);
  }, [data, page, rowsPerPage]);

  return { visibleData };
};
