import React from 'react';
import { Slider, Stack, Typography } from '@mui/material';

interface Props {
  disabled: boolean;
  label: string;
  max: number;
  min: number;
  val: number[];
  setVal: (val: number[]) => void;
}

export const LabeledSlider: React.FC<Props> = (props) => {
  const { disabled, label, max, min, setVal, val } = props;

  const changeSliderHandler = (_: unknown, newVal: number | number[]) => {
    if (Array.isArray(newVal) && newVal.length === 2) {
      setVal(newVal);
    }
  };

  return (
    <Stack gap={1} width="100%">
      <Typography>{label}</Typography>

      <Slider
        disabled={disabled}
        value={val}
        valueLabelDisplay="auto"
        onChange={changeSliderHandler}
        max={max}
        min={min}
      />
    </Stack>
  );
};
