import { useContext, useEffect } from 'react';
import { AlertContext } from '../context/AlertContext';
import { useTranslation } from 'react-i18next';
import { useGetBusinessUnitsQuery } from '../store/services/api';

export const useBusinessUnits = () => {
  const { data, error, isFetching } = useGetBusinessUnitsQuery();
  const { setMessage } = useContext(AlertContext);
  const { t } = useTranslation();

  useEffect(() => {
    if (error) {
      setMessage(t('errors.fetchBusinessUnits', { error }), 'error');
    }
  }, [error]);

  return { businessUnits: data ? data.data : [], isFetching };
};
