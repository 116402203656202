import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';
import React from 'react';
import { ProjectSummaryTableHead } from './ProjectSummaryTableHead';
import { ProjectSummaryTableRow } from './ProjectSummaryTableRow';
import { Data } from './types';
import { useSortedData } from '../../hooks/useSortedData';
import { useVisibleData } from '../../hooks/useVisibleData';
import { OrderBy } from './ProjectSummaryTable';

interface Props {
  changeSelection: (id: string) => void;
  isSelected: (id: string) => boolean;
  onSelect?: (selectedProjectId: string) => void;
  rows: Data[];
  page: number;
  rowsPerPage: number;
  selectable?: boolean;
  selected: readonly string[];
  setSelected: (selected: readonly string[]) => void;
  orderBy: OrderBy;
  setOrderBy: React.Dispatch<React.SetStateAction<OrderBy>>;
}

export const ProjectSummaryTableContainer: React.FC<Props> = (props) => {
  const {
    changeSelection,
    isSelected,
    onSelect,
    page,
    rows,
    rowsPerPage,
    selectable,
    selected,
    setSelected,
    orderBy,
    setOrderBy,
  } = props;

  const order = orderBy.ascending === true ? 'asc' : 'desc';

  const { sortedData } = useSortedData(rows, order, orderBy.orderBy);
  const { visibleData } = useVisibleData(sortedData, page, rowsPerPage);

  const handleRequestSort = (
    _: React.MouseEvent<unknown>,
    property: keyof Data,
  ) => {
    setOrderBy(prevState => ({
      ...prevState,
      orderBy: property,
      ascending: orderBy.ascending ? false : true
    }));
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      if (selected.length !== 0) {
        setSelected([]);
      } else {
        const newSelected = rows.map((n) => n.id);
        setSelected(newSelected);
      }
      return;
    }
    setSelected([]);
  };

  const handleClick = (id: string) => {
    if (selectable) {
      changeSelection(id);
    }
  };

  return (
    <TableContainer>
      <Table sx={{ minWidth: 750 }} size="small">
        <ProjectSummaryTableHead
          selectionConfig={
            selectable
              ? {
                  numSelected: selected.length,
                  onSelectAllClick: handleSelectAllClick,
                  rowCount: rows.length,
                }
              : undefined
          }
          onRequestSort={handleRequestSort}
          order={order}
          orderBy={orderBy.orderBy}
        />

        <TableBody>
          {visibleData.map((row) => (
            <ProjectSummaryTableRow
              onSelect={() => onSelect?.(row.id)}
              row={row}
              selectable={selectable}
              onClick={handleClick}
              isSelected={isSelected(row.id)}
              key={`table-row-${row.id}`}
            />
          ))}

          {emptyRows > 0 && (
            <TableRow
              style={{
                height: 33 * emptyRows,
              }}
            >
              <TableCell colSpan={6} />
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
