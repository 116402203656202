import React, { SyntheticEvent, useEffect, useState } from 'react';
import { useBusinessUnits } from '../../hooks/useBusinessUnits';
import {
  Autocomplete,
  CircularProgress,
  Stack,
  TextField,
} from '@mui/material';
import { BusinessUnit } from '../../api';

interface Props {
  label?: string;
  onChangeSelectedId: (selectedId: string | undefined) => void;
  id?: string;
  isRequired: boolean;
}

interface Value {
  label: string;
  id: string;
}

export const BusinessUnitSelector: React.FC<Props> = (props) => {
  const { onChangeSelectedId, id, label, isRequired } = props;
  const [value, setValue] = useState<Value | undefined | null>(null);

  const [options, setOptions] = useState<Record<string, BusinessUnit>>({});

  const { businessUnits, isFetching } = useBusinessUnits();

  useEffect(() => {
    if (!businessUnits) {
      return;
    }

    const opts: Record<string, BusinessUnit> = businessUnits.reduce(
      (prev: Record<string, BusinessUnit>, curr: BusinessUnit) => {
        const { id } = curr;
        prev[id] = curr;

        return prev;
      },
      {} as Record<string, BusinessUnit>,
    );
    setOptions(opts);
  }, [businessUnits.length]);

  const handleChange = (_: SyntheticEvent, value?: Value | null) => {
    if (!value?.id) {
      onChangeSelectedId(undefined);

      return;
    }

    const unit = options[value.id];

    if (unit) {
      onChangeSelectedId(unit.id);
    }
  };

  useEffect(() => {
    if (!id) {
      setValue(null);

      return;
    }

    const option = Object.entries(options).find(
      ([optUid, _]) => optUid === id,
    );

    if (!option) {
      setValue(null);

      return;
    }

    const [_, businessUnit] = option;

    setValue({ label: businessUnit.name, id });
  }, [id, options]);

  if (isFetching) {
    return (
      <Stack alignItems="center">
        <CircularProgress />
      </Stack>
    );
  }

  return (
    <Autocomplete
      disablePortal
      options={Object.values(options)
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((bUnit) => ({ label: bUnit.name, id: bUnit.id }))}
      sx={{ width: 480 }}
      onChange={handleChange}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option.id}>
            {option.label}
          </li>
        );
      }}
      renderInput={(params) => <TextField
        {...params}
        required={isRequired}
        label={label}
        placeholder={label}
      />}
      value={value}
      isOptionEqualToValue={(option?: Value, value?: Value) => {
        if (!option?.id && !value?.id) {
          return true;
        }

        if (!option?.id || !value?.id) {
          return false;
        }

        return option.id === value.id;
      }}
    />
  );
};
