import { AlertColor } from '@mui/material';
import { createContext } from 'react';

export type AlertContextValue = {
  message?: string;
  severity?: AlertColor;
  setMessage: (message?: string, severity?: AlertColor) => void;
};

export const AlertContext = createContext<AlertContextValue>({
  message: '',
  setMessage: () => {},
  severity: 'success',
});
