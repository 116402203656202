import { DialogProps } from '@mui/material';
import React from 'react';
import { InteractionDialog } from '../InteractionDialog';
import { useTranslation } from 'react-i18next';

interface Props extends DialogProps {
  closeDialog: () => void;
  onClose?: () => void;
  onSave: () => void;
  resetForm: () => void;
}

export const ConfirmationDialog: React.FC<Props> = (props) => {
  const { onClose, onSave, closeDialog, resetForm, ...rest } = props;
  const { t } = useTranslation();

  const handleSaveAndExit = () => {
    onSave();
    closeDialog();
    onClose?.();
  };

  const handleDiscardChanges = () => {
    resetForm();
    closeDialog();
    onClose?.();
  };

  return (
    <InteractionDialog
      title={t('references.edit.unsavedChanges.title')}
      description={t('references.edit.unsavedChanges.description')}
      interactions={[
        {
          text: t('references.edit.unsavedChanges.continueEditing'),
          onClick: closeDialog,
        },
        {
          text: t('references.edit.unsavedChanges.saveAndExit'),
          onClick: handleSaveAndExit,
          color: 'success',
        },
        {
          text: t('references.edit.unsavedChanges.discardChanges'),
          onClick: handleDiscardChanges,
          color: 'warning',
        },
      ]}
      {...rest}
    />
  );
};
