import Button from '@mui/material/Button';
import { useMsal } from '@azure/msal-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const SignInButton = () => {
    const { t } = useTranslation();
    const { instance } = useMsal();
    const authEnabled = process.env.CONFIG_REQUIRE_AUTH !== "false"
    const handleSignIn = () => {
        instance.loginRedirect({
            scopes: ['api://a608d2e8-592d-4e58-87fe-ca186d02fd18/ReferenceTool.Use']
        });
    }
    return (
        authEnabled && <Button onClick={handleSignIn}>{t('signIn')}</Button>
    )
};