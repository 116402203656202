
const translation = {
  selected: 'selected',
  copyToClipboardButton: 'Copied to clipboard',
  enableLanguageSelector: 'Translate into another language',
  languageSelector: {
    languages: 'Language',
    selectLanguage: 'Select language',
  },
  signIn: 'Sign In',
  signOut: 'Sign Out',
  signInPrompt: 'You are not signed in',
  save: 'Save',
  cancel: 'Cancel',
  close: 'Close',
  edit: 'Edit',
  cv: {
    downloadCV: 'Download CV',
    filename: 'CV_references_{{uid}}.docx',
  },
  download: 'Download',
  downloadStarted: 'Download started',
  includeSeveraAndMajakkaIDs: "Include Severa and Majakka IDs",
  localizationLanguages: {
    fi: 'Finnish',
    en: 'English',
    sv: 'Swedish',
    da: 'Danish',
  },
  errors: {
    download: 'Error downloading file: {{error}}',
    fetchPersonCards: 'Error fetching people: {{error}}',
    fetchProject: 'Error fetching project {{error}}',
    fetchProjectSummaries: 'Error fetching project summaries: {{error}}',
    fetchReference: 'Error fetching a reference: {{error}}',
    saveProject: 'Error saving project: {{error}}',
    saveReference: 'Error saving reference: {{error}}',
  },
  tooltips: {
    projects: {
      referenceStatus: 'Shows in which languages the ended project has all approved references',
      scope: 'Scope of project in euros',
    },
  },
  nav: {
    cv: 'Employee CV',
    heading: 'NHG Reference Tool',
    references: 'Reference export and edit',
    refEdit: 'Reference edit',
  },
  projects: {
    downloadForPowerpoint: 'Download for Powerpoint',
    downloadForWord: 'Download for Word',
    ended: 'Ended',
    label: 'Projects',
    name: 'Name',
    started: 'Started',
    scope: '€',
    edit: {
      saveSuccess: 'Project details successfully changed.'
    },
    search: {
      customer: 'Customer',
      fullTextSearch: 'Full text search (or Severa #)',
      heading: 'Search for projects',
      projectOwner: 'Project owner',
      salesperson: 'Select salesperson',
      businessUnit: 'Select business unit',
      projectWorker: 'Select project owner',
      scopeInManDays: 'Scope (man days)',
      timeframeYearsAgo: 'Timeframe (years ago)',
    },
  },
  references: {
    edit: {
      additionalInformation: {
        label: 'NHG Team',
        placeholder: 'NHG’s contact persons / people involved',
      },
      customerContacts: {
        label: 'Customer contact(s)',
        placeholder: 'Name and email',
      },
      cvReference: {
        label: 'CV Reference',
        placeholder: 'Concise description for CVs and offers, avoiding bullet points. Clearly describe project, objectives, and results without introducing new issues not in the long reference.',
      },
      discardChanges: {
        description: 'Do you want to discard the changes?',
        discard: 'Discard changes',
      },
      duration: {
        label: 'Duration',
        placeholder: '',
      },
      execution: {
        label: 'Execution',
        placeholder: 'Describe project structure - phases & sub-projects, e.g., “Analysis, Development, Implementation, Evaluation.”',
      },
      introduction: {
        label: 'Introduction',
        placeholder: 'Brief project introduction, e.g., “SAKARI: Joint-project funded by Social and Welfare Ministry to improve elderly home care services.”',
      },
      methods: {
        label: 'Methods',
        placeholder: 'List ALL methods used. Methods may also be listed as part of Execution.',
      },
      objectives: {
        label: 'Objectives',
        placeholder: 'Describe project objectives concretely, e.g., “Develop and implement adaptive, customer-oriented home care service model.”',
      },
      results: {
        label: 'Results',
        placeholder: 'Describe concrete results, actions, and impacts, possibly divided into phases, e.g., “City of Porvoo increased effective work time by 9%-points, reducing substitutes by 37%. Workbook created for model implementation available at www.xxx.fi.”',
      },
      saveSuccess: 'Reference saved',
      translationSuccess: 'Translations created successfully',
      scope: {
        label: 'Scope',
        placeholder: '',
      },
      unsavedChanges: {
        continueEditing: 'Continue editing',
        description:
          'Do you want to save your changes before exiting the editor?',
        descriptionLanguageChange: 'Do you want to save your changes?',
        discardChanges: 'Discard changes and exit',
        discardChangesAndChangeLanguage: 'Discard changes',
        saveAndExit: 'Save and exit',
        title: 'You have unsaved changes',
        save: 'Save changes',
      },
    },
  },
  peopleSelector: {
    selectEmployee: 'Select employee',
  },
  icons: {
    hasReferences: 'Project has unapproved references',
    hasCVReference: 'Project does not have a CV Reference',
  },
};

export default translation;
