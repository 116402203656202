import { Stack } from '@mui/material';
import React, { useContext, useEffect } from 'react';
import { ProjectSearch } from './ProjectSearch';
import { ProjectSummaryTable } from './ProjectSummaryTable';
import { useTranslation } from 'react-i18next';
import { AlertContext } from '../../context/AlertContext';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { useGetProjectsQuery } from '../../store/services/api';

interface Props extends React.PropsWithChildren {
  onSelect?: (selectedProjectId: string) => void;
  onChangeSelected?: (selected: readonly string[]) => void;
}

export const ProjectSummaries: React.FC<Props> = (props) => {
  const { children, onSelect, onChangeSelected } = props;

  const search = useSelector(
    (state: RootState) => state.projectSummarySearchOpts,
  );
  const { error } = useGetProjectsQuery(search);
  const { t } = useTranslation();

  const { setMessage } = useContext(AlertContext);

  useEffect(() => {
    if (error) {
      setMessage(t('errors.fetchProjectSummaries', { error }), 'error');
    }
  }, [error]);

  return (
    <Stack direction="row" gap={4} width="80%">
      <Stack gap={4} flex={1}>
        <ProjectSearch />
      </Stack>

      <Stack gap={4} flex={2} marginBottom={10}>
        <ProjectSummaryTable
          onSelect={onSelect}
          onChangeSelected={onChangeSelected}
          selectable={!!onChangeSelected}
        />
        {children}
      </Stack>
    </Stack>
  );
};
