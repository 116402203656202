import { Checkbox, TableCell, TableRow, Box } from '@mui/material';
import React from 'react';
import { Data } from './types';
import { useTranslation } from 'react-i18next';
import FlagIcon from '../flagIcon.tsx';

interface Props {
  row: Data;
  selectable?: boolean;
  onClick: (rowId: string, name: string) => void;
  isSelected: boolean;
  onSelect: (rowId: string, name: string) => void;
}

export const ProjectSummaryTableRow: React.FC<Props> = (props) => {
  const { isSelected, onClick, onSelect, row, selectable } = props;
  const { t } = useTranslation();

  const languageCodes: string[] = ['fi', 'gb', 'sv', 'dk']

  const handleTableCellClick = () => onSelect?.(row.id, row.name);

  const projectHasEndDate = ( endedDate: Date | undefined ) => {
    if (endedDate !== undefined) {
      return true
    } else {
      return false;
    }
  }

  return (
    <TableRow
      hover
      role="checkbox"
      tabIndex={-1}
      key={row.id}
      selected={isSelected}
    >
      {selectable && (
        <TableCell padding="checkbox">
          <Checkbox 
            sx={{ cursor: 'pointer' }} 
            color="primary" 
            checked={isSelected} 
            onClick={() => onClick(row.id, row.name)} 
          />
        </TableCell>
      )}
      <TableCell 
        sx={{ 
          cursor: 'pointer', 
          display: 'flex', 
          justifyContent: 'left', 
          alignItems: 'center' 
        }} 
        onClick={handleTableCellClick}
      >
        {row.name}
      </TableCell>
      <TableCell onClick={handleTableCellClick} align="left">
        { projectHasEndDate(row.ended) && (
          <Box display="flex" alignItems="center">
            <DisplayFlagIcon
              opacity={row.referenceStatus?.hasFinnishAllApproved && projectHasEndDate(row.ended) ? 1 : 0.3}
              toolTipText={row.referenceStatus?.hasFinnishAllApproved && projectHasEndDate(row.ended) ? 
                `All ${t('localizationLanguages.fi')} references approved` :
                `Action required: Approve some or all ${t('localizationLanguages.fi')} references`}
              flagType={languageCodes[0]}
            />
            <DisplayFlagIcon
              opacity={row.referenceStatus?.hasEnglishAllApproved && projectHasEndDate(row.ended) ? 1 : 0.3} 
              toolTipText={row.referenceStatus?.hasEnglishAllApproved && projectHasEndDate(row.ended) ? 
                `All ${t('localizationLanguages.en')} references approved` :
                `Action required: Approve some or all ${t('localizationLanguages.en')} references`}
              flagType={languageCodes[1]}
            />
            <DisplayFlagIcon
              opacity={row.referenceStatus?.hasSwedishAllApproved && projectHasEndDate(row.ended) ? 1 : 0.3} 
              toolTipText={row.referenceStatus?.hasSwedishAllApproved && projectHasEndDate(row.ended) ? 
                `All ${t('localizationLanguages.sv')} references approved` :
                `Action required: Approve some or all ${t('localizationLanguages.sv')} references`}
              flagType={languageCodes[2]}
            />
            <DisplayFlagIcon
              opacity={row.referenceStatus?.hasDanishAllApproved && projectHasEndDate(row.ended) ? 1 : 0.3} 
              toolTipText={row.referenceStatus?.hasDanishAllApproved && projectHasEndDate(row.ended) ? 
                `All ${t('localizationLanguages.da')} references approved` :
                `Action required: Approve some or all ${t('localizationLanguages.da')} references`} 
              flagType={languageCodes[3]}
            />
        </Box>)}
      </TableCell>
      <TableCell 
        sx={{ cursor: 'pointer' }} 
        onClick={handleTableCellClick} 
        align="left"
      >
        {row.scope ? `${row.scope} €` : "-"}
      </TableCell>
      <TableCell 
        sx={{ cursor: 'pointer' }} 
        onClick={handleTableCellClick}
      >
        {row.started?.toLocaleDateString()}
      </TableCell>
      <TableCell 
        sx={{ cursor: 'pointer' }} 
        onClick={handleTableCellClick}
      >
        {row.ended?.toLocaleDateString()
      }</TableCell>
    </TableRow>
  );
};

interface DisplayFlagIconProps {
  opacity: number;
  toolTipText: string;
  flagType: string;
};

export const DisplayFlagIcon: React.FC<DisplayFlagIconProps> = ({
  opacity, 
  toolTipText, 
  flagType, 
}) => {

  return (
    <FlagIcon
      transform="scale(1.1, 1.4)"
      transformOrigin="center center"            
      width='13px'
      border='1px solid grey'
      borderRadius='20px'
      opacity={opacity} 
      marginRight='4px'
      tooltipText={toolTipText} 
      flagType={flagType}
    />
  )
};
