import React from 'react';
import { DialogProps } from '@mui/material';
import { InteractionDialog } from '../InteractionDialog';
import { useTranslation } from 'react-i18next'; 

interface Props extends DialogProps {
    closeDialog: () => void;
    resetForm: () => void;
    onSave: () => void;
    onClose: () => void;
}

export const ChangeLanguageDialog: React.FC<Props> = ( props ) => {
    const { closeDialog, resetForm, onSave, onClose, ...rest } = props;
    const { t } = useTranslation();

    const handleSave = () => {
        onSave();
        closeDialog();
        onClose?.();
    };

    const handleDiscardChanges = () => {
        resetForm();
        closeDialog();
        onClose?.();
    };

    return (
        <InteractionDialog 
            title={t('references.edit.unsavedChanges.title')}
            description={t('references.edit.unsavedChanges.descriptionLanguageChange')}
            interactions={[
                {
                    text: t('references.edit.unsavedChanges.continueEditing'),
                    onClick: closeDialog,
                },
                {
                    text: t('references.edit.unsavedChanges.save'),
                    onClick: handleSave,
                    color: 'success'
                }, 
                {
                    text: t('references.edit.unsavedChanges.discardChangesAndChangeLanguage'),
                    onClick: handleDiscardChanges,
                    color: 'warning'
                },
            ]}
            {...rest}
        />
    )
};