import { CircularProgress, Stack, Toolbar, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { alpha } from '@mui/material/styles';

interface Props {
  numSelected?: number;
  isFetching: boolean;
}

export const TableToolbar: React.FC<Props> = (props) => {
  const { isFetching, numSelected } = props;
  const { t } = useTranslation();

  if (isFetching) {
    return (
      <Stack paddingX={0} paddingY={1} alignItems="center">
        <CircularProgress />
      </Stack>
    );
  }

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected &&
          numSelected > 0 && {
            bgcolor: (theme) =>
              alpha(
                theme.palette.primary.main,
                theme.palette.action.activatedOpacity,
              ),
          }),
      }}
    >
      {numSelected && numSelected > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {`${numSelected} ${t('selected')}`}
        </Typography>
      ) : (
        <Typography sx={{ flex: '1 1 100%' }} variant="h6" component="div">
          {t('projects.label')}
        </Typography>
      )}
    </Toolbar>
  );
};
